module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Quarkus Extensions","short_name":"Extensions","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"src/images/quarkus-logo-black-background.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7fdc620c66c7344980baaad2d65df0d9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"iRnKCc3jLPh1UfbuZd1fcQFlDOhPVS6L","devKey":"iRnKCc3jLPh1UfbuZd1fcQFlDOhPVS6L","customSnippet":"(function(){  var doNotTrack = navigator.doNotTrack == 1; if (doNotTrack){window.analytics={ trackSubmit: ()=>{}, trackClick: ()=>{}, trackLink: ()=>{}, trackForm: ()=>{}, pageview: ()=>{}, identify: ()=>{}, reset: ()=>{}, group: ()=>{}, track: ()=>{}, ready: ()=>{}, alias: ()=>{}, debug: ()=>{}, page: ()=>{}, once: ()=>{}, off: ()=>{}, on: ()=>{}, addSourceMiddleware: ()=>{}, addIntegrationMiddleware: ()=>{}, setAnonymousId: ()=>{}, addDestinationMiddleware: ()=>{}};}else {var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error(\"Segment snippet included twice.\");else{analytics.invoked=!0;analytics.methods=[\"trackSubmit\",\"trackClick\",\"trackLink\",\"trackForm\",\"pageview\",\"identify\",\"reset\",\"group\",\"track\",\"ready\",\"alias\",\"debug\",\"page\",\"once\",\"off\",\"on\",\"addSourceMiddleware\",\"addIntegrationMiddleware\",\"setAnonymousId\",\"addDestinationMiddleware\"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement(\"script\");t.type=\"text/javascript\";t.async=!0;t.src=\"${host}/analytics.js/v1/\" + key + \"/analytics.min.js\";var n=document.getElementsByTagName(\"script\")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=\"${writeKey}\";analytics.SNIPPET_VERSION=\"4.15.3\";analytics.reset();\n    gatsbySegmentLoad('${writeKey}');\n    window.gatsbyPluginSegmentPageviewCaller();\n  }}})();"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
